import styled from 'styled-components';

export const LoadingContainer = styled.div`

    .box {
    align-self: flex-end;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    background-color: #F44336;
    height: 200px;
    margin: 0 auto 0 auto;
    // transform-origin: bottom;
    width: 200px;
    }

    .bounce {
      animation: bounce 2s ease 0s infinite normal none;
      opacity:50%;
      // animation-iteration-count: infinite ;
      // background-color: #F44336;
      // width: 50%;
      // height: 50%;
      // animation-name: bounce;
      // animation-timing-function: ease-in-out;
    }
    @keyframes bounce {
    from {  transform: translateY(0px);  }
        50% { transform: translateY(0.10rem);  }
        to {  transform: translateY(0px);  }
  }
`;