import React, {useContext, useEffect, useState} from 'react';
import useIsSmallScreen from '../../../useIsSmallScreen/useIsSmallScreen.js';

import { AppContext } from '../../../context';
import DOMPurify from 'dompurify';
import axios from 'axios';
import {
  getDictUserSources,
  getUserFilters
} from '../../../functions/manageSessions';
import {
  isUrlPdf,
} from '../../../functions/utils.js';
import Hr from '../../hr/index.js';
import { Container } from 'react-bootstrap';
import SuggestedQuestionContainer from '../suggested-question-container/index.js';
import GeneratedAnswer from '../generated-answer/index.js';
import ExtractiveAnswer from '../extractive-answer/index.js';
import ExtractiveAnswerSecondEqa from '../second-extractive-answer/index.js';
import { is_single_source } from '../../../functions/frontend_variables';




const OutsideFilerContainer = () => {
    const {
        fetchedResults,
        userContext,
        setActiveChatIndex,
        setLoadMore,
        setQueueDone,
        setClearLocalWebResults,
        searchContext,
        llmQuestion,
      } = useContext(AppContext);
  const isSmallScreen = useIsSmallScreen();
  const handleLoadMore = () => {
    setActiveChatIndex(0);
    setLoadMore(true);
    setQueueDone(false);
    setClearLocalWebResults(false);
  };
  const [allPublishersNames, setAllPublishersNames] = useState([]);
  const [purifyHtml, setPurifyHtml] = useState([]);
  const [isMagicUser, setIsMagicUser] = useState(false);
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [bookmarksFetched, setBookmarksFetched] = useState(false);
  const [resultHasBookmark, setResultHasBookmark] = useState([]); // an array representing weather or the results is bookmarked
  const [updateBookmarks, setUpdateBookmarks] = useState(false); // TODO Change to trigger bookmark update
  const [resultFavouriteID, setResultFavouriteID] = useState([]); // an array of favourite ids if the results has no favoiryt id = -1
  const [resultHasFavourite, setResultHasFavourite] = useState([]); // an array representing weather or the results is favourite
  const [favouriteRemove, setFavouriteRemove] = useState([]); // an array of favourite to be remove
  const [publishersSources, setPublishersSources] = useState([]);
  const [isOnlineViewUrlPdf, setIsOnlineViewUrlPdf] = useState([]);
  const [onlineViewUrl, setOnlineViewUrl] = useState([]);
  const [favourites, setFavourites] = useState(undefined);


  // loops through all favorite & returns id of favorite
  // input : a result obj
  // out: a int
  // if no favorite -1  si return
  const getFavouriteID = (result) => {
    let id = -1;
    if (favourites && result) {
      favourites.forEach((favourite) => {
        if (
          favourite &&
          favourite['online_view_url'] &&
          result['online_view_url'] &&
          favourite['online_view_url'] === result['online_view_url']
        ) {
          id = favourite['id'];
        }
      });
    }
    return id;
  };

  // input: a string representing a encrypted_url
  // output: a object
  const get_publisher_source = (encrypted_url) => {
    const default_sources_dict = getDictUserSources();
    let out = undefined;
    if (
      default_sources_dict[encrypted_url] &&
      default_sources_dict[encrypted_url].name &&
      default_sources_dict[encrypted_url].name.trim() !== ''
    ) {
      out = default_sources_dict[encrypted_url];
    }
    return out;
  };

  const handleExtractiveInteractionEqa = (e, result) => {
    e.stopPropagation();
    let selectedSourcesIDs = [];
    let facets = searchContext.facets;
    if ((!is_single_source) && facets && facets.length !== 0) {
      searchContext.facets.forEach(source => {
        // if it is a src object 
        if (source && source["encrypted_url"] && (source['name'] !=="Web" || facets.length ===1 )){ 
        //check source is valid before sending a request
          if(source.hasOwnProperty('id')){
            selectedSourcesIDs.push(source['id']);
          };
        }
        // else it is not a src but an display filter add array of sources
        else {
          if(source && source['sources']&& (source['name'] !=="Web" || facets.length ===1 )){
            source['sources'].forEach(source => {
              if(source.hasOwnProperty('id')){
                selectedSourcesIDs.push(source['id']);
              }
            });
          }
        }
      });
    };
    let assisted_response = {...result, chunk_title: result.title};
    delete assisted_response.title;
    let body = {
      online_view_url: result.online_view_url,
      interaction_type: 'visit',
      assisted_response: [assisted_response],
      selected_sources: selectedSourcesIDs,
      get_filters_response: getUserFilters(),
    };
    // TO DO move to useEffect
    // request should be in useEffect to allow for cancel at clean up
    if (searchContext.q) {
      body = {
        ...body,
        q: searchContext.q,
      };
    };
    if (llmQuestion) {
      body = {
        ...body,
        suggested_question: llmQuestion,
        eqa_tracker:0,
      };
    };
    const config = {
      method: 'post',
      url: `${window.BASE_URL_USER}log-result-interaction`,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(body),
    };
    axios(config);
  };
  const handleExtractiveInteractionEqaTwo = (e, result) => {
    e.stopPropagation();
    let selectedSourcesIDs = [];
    let facets = searchContext.facets;
    if ((!is_single_source) && facets && facets.length !== 0) {
      searchContext.facets.forEach(source => {
        // if it is a src object 
        if (source && source["encrypted_url"] && (source['name'] !=="Web" || facets.length ===1 )){ 
        //check source is valid before sending a request
          if(source.hasOwnProperty('id')){
            selectedSourcesIDs.push(source['id']);
          };
        }
        // else it is not a src but an display filter add array of sources
        else {
          if(source && source['sources']&& (source['name'] !=="Web" || facets.length ===1 )){
            source['sources'].forEach(source => {
              if(source.hasOwnProperty('id')){
                selectedSourcesIDs.push(source['id']);
              }
            });
          }
        }
      });
    };
    let assisted_response = {...result, chunk_title: result.title};
    delete assisted_response.title;
    let body = {
      online_view_url: result.online_view_url,
      interaction_type: 'visit',
      assisted_response: [assisted_response],
      selected_sources: selectedSourcesIDs,
      get_filters_response: getUserFilters(),
    };
    // TO DO move to useEffect
    // request should be in useEffect to allow for cancel at clean up
    if (searchContext.q) {
      body = {
        ...body,
        q: searchContext.q,
      };
    };
    if (llmQuestion) {
      body = {
        ...body,
        suggested_question: llmQuestion,
        eqa_tracker:1,
      };
    };
    const config = {
      method: 'post',
      url: `${window.BASE_URL_USER}log-result-interaction`,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(body),
    };
    axios(config);
  };

  const callbackResultExpanded = (result, i) => {
    return () => {
      result.expanded = 'expanded';
      result.noteExpanded = true;
    };
  };

  // update arrays keeping track of favorite results
  const updateFavourites = (i, id) => {
    let temp_favourite = resultHasFavourite;
    let temp_id = resultFavouriteID;
    let favourite_remove = favouriteRemove;
    if (temp_favourite) {
      temp_favourite[i] = !temp_favourite[i];
      temp_id[i] = id;

      setResultHasFavourite(temp_favourite); // updated hasfavority
      setResultFavouriteID(temp_id); // update favourite
      if (temp_favourite[i] === false) {
        // if we are removing a favorite we need to updte the favouriteRemove array
        favourite_remove[i] = true;
        setFavouriteRemove(favourite_remove);
      } else {
        favourite_remove[i] = false;
        setFavouriteRemove(favourite_remove);
      }
    }
  };

  // is UrlPDF should not be call at render but at the start
  useEffect(() => {
    //isUrlPdf(result.online_view_url)
    let newArrayPdf = [];
    let publishersNames = [];
    let favouritesIDArray = [];
    let onlineViewUrlArray = [];
    let allPublishersSources = [];
    let publishersUrl = [];
    let currentPurifyHtml = [];

    if (fetchedResults) {
      fetchedResults.forEach((result) => {
        newArrayPdf.push(isUrlPdf(result.online_view_url)); // isUrlPdf() only works with online_view_url so no need to check online_pdf_url 
        favouritesIDArray.push(getFavouriteID(result));
        publishersUrl.push(result.doc_publisher);
        let publisher = get_publisher_source(result.doc_publisher);
        if (typeof publisher === 'undefined' && result.online_view_url) {
          allPublishersSources.push({ url: result.online_view_url });
        } else {
          allPublishersSources.push(publisher);
        }
        let tempName = result.doc_publisher;
        if (publisher !== undefined && publisher['name']) {
          tempName = publisher['name'];
        }
        publishersNames.push(tempName);
        onlineViewUrlArray.push(result.online_view_url);
      });
      setIsOnlineViewUrlPdf(newArrayPdf);
      setResultFavouriteID(favouritesIDArray);
      setFavouriteRemove([]);
      setAllPublishersNames(publishersNames);
      setPublishersSources(allPublishersSources);
      setOnlineViewUrl(onlineViewUrlArray);
      // purify html
      DOMPurify.addHook('afterSanitizeElements', function (node) {
        // console.log(node);
        if ('target' in node) {
          node.setAttribute('rel', 'noopener noreferrer');
          node.setAttribute('target', '_blank');
        }
        if (node.nodeName && node.nodeName === 'P') {
          // node.textContent = node.textContent.toUpperCase();
          if (
            node.textContent.trim().split(' ').length <= 1 &&
            node.parentNode
          ) {
            node.insertAdjacentHTML('afterend', node.textContent);
            node.remove();
          }
        }
      });
      fetchedResults.forEach((result) => {
        if (result['text']) {
          let out = DOMPurify.sanitize(result['text'], {
            FORBID_TAGS: ['img'],
            ADD_ATTR: ['target'], 
          });
          currentPurifyHtml.push(out);
        } else {
          currentPurifyHtml.push('');
        }
      });
    }
    setPurifyHtml(currentPurifyHtml);
  }, [fetchedResults]);
 
  return (
    <div style={{padding:' 0 0 0 0.32rem'}}>
      {/* SUGGESTED QUESTION */}
      {/* <SuggestedQuestionContainer></SuggestedQuestionContainer> */}
      {/* Generated Anwser from Miah */}
      {userContext && Object.hasOwn(userContext,'ai_answer_type') &&  (userContext['ai_answer_type'] === "extractive"  || userContext['ai_answer_type'] === "generative") ? (
        <>
          {userContext['ai_answer_type'] === "generative" && (
            <>
            <GeneratedAnswer references={fetchedResults} allPublishersNames={allPublishersNames} purifyHtml={purifyHtml}
              isOnlineViewUrlPdf={isOnlineViewUrlPdf} publishersSources={publishersSources} bookmarksFetched={bookmarksFetched}
              resultHasBookmark={resultHasBookmark} updateBookmarks={updateBookmarks} setUpdateBookmarks={setUpdateBookmarks}
              resultHasFavourite={resultHasFavourite} favouriteRemove={favouriteRemove} resultFavouriteID={resultFavouriteID}
              isLoggedin={isLoggedin} isMagicUser={isMagicUser} updateFavourites={updateFavourites}
              callbackResultExpanded={callbackResultExpanded}></GeneratedAnswer>
              {/* maybe use in the future but for now "generative" is never used.  */}
             {/* { userContext['two_eqa_containers_enabled'] &&  (
               <GeneratedAnswer references={fetchedResults} allPublishersNames={allPublishersNames} purifyHtml={purifyHtml}
              isOnlineViewUrlPdf={isOnlineViewUrlPdf} publishersSources={publishersSources} bookmarksFetched={bookmarksFetched}
              resultHasBookmark={resultHasBookmark} updateBookmarks={updateBookmarks} setUpdateBookmarks={setUpdateBookmarks}
              resultHasFavourite={resultHasFavourite} favouriteRemove={favouriteRemove} resultFavouriteID={resultFavouriteID}
              isLoggedin={isLoggedin} isMagicUser={isMagicUser} updateFavourites={updateFavourites}
              callbackResultExpanded={callbackResultExpanded}></GeneratedAnswer>) 
             } */}
             </>
          )}
          {userContext['ai_answer_type'] === "extractive" && (
            <>
            <ExtractiveAnswer references={fetchedResults} allPublishersNames={allPublishersNames} purifyHtml={purifyHtml}
              isOnlineViewUrlPdf={isOnlineViewUrlPdf} publishersSources={publishersSources} bookmarksFetched={bookmarksFetched}
              resultHasBookmark={resultHasBookmark} updateBookmarks={updateBookmarks} setUpdateBookmarks={setUpdateBookmarks}
              resultHasFavourite={resultHasFavourite} favouriteRemove={favouriteRemove} resultFavouriteID={resultFavouriteID}
              isLoggedin={isLoggedin} isMagicUser={isMagicUser} updateFavourites={updateFavourites}
              callbackResultExpanded={callbackResultExpanded} handleLinkoutClick={handleExtractiveInteractionEqa}></ExtractiveAnswer>
            { userContext['two_eqa_containers_enabled'] &&  ( 
              // <SuggestedQuestionContainer></SuggestedQuestionContainer>
              <ExtractiveAnswerSecondEqa references={fetchedResults} allPublishersNames={allPublishersNames} purifyHtml={purifyHtml}
              isOnlineViewUrlPdf={isOnlineViewUrlPdf} publishersSources={publishersSources} bookmarksFetched={bookmarksFetched}
              resultHasBookmark={resultHasBookmark} updateBookmarks={updateBookmarks} setUpdateBookmarks={setUpdateBookmarks}
              resultHasFavourite={resultHasFavourite} favouriteRemove={favouriteRemove} resultFavouriteID={resultFavouriteID}
              isLoggedin={isLoggedin} isMagicUser={isMagicUser} updateFavourites={updateFavourites}
              callbackResultExpanded={callbackResultExpanded} handleLinkoutClick={handleExtractiveInteractionEqaTwo}></ExtractiveAnswerSecondEqa>
              )}
            </>
          )} 
          <Container>
            <div style={{ padding: "8px", display: 'flex', justifyContent:'center', alignItems:'center' }} >
              <Hr style={{width:'30%'}}/>
            </div>
          </Container>
        </>
        )
        :(
          <><SuggestedQuestionContainer></SuggestedQuestionContainer> </>
        )}
    </div>
  );
};

export default OutsideFilerContainer;
