import React, {useContext, useEffect, useState, useMemo, useCallback, lazy, Suspense} from 'react';
// import useIsSmallScreen from '../../useIsSmallScreen/useIsSmallScreen.js';
import useIsSmallScreen from '../../useIsSmallScreen/useIsSmallScreen.js';
import { AppContext } from '../../context';
import { LoadingContainer } from './styles.js';
// import {ReactComponent a/s ErrorIcon }from '../../../assets/svgs/error_23dp.svg';

const LoadingIndicatorWithMessages = (props) => {
  const {
      userContext,
      searchContext,
    } = useContext(AppContext);
  const isSmallScreen = useIsSmallScreen();
//[your organisation/version] / [Key external sources]
//your organisation
  
  const [messages,setMessages]  = useState(["Analysing query","Retrieving sources from national guidelines","Looking for an answer within these sources","Fact-checking to ensure there are no AI hallucinations"]) ; 
  const [displayMessage, setDisplayMessage] = useState("Analysing query");
  const [index, setIndex] = useState(0);
  const [timer, setTimer]= useState(1500);

  useEffect(() => {
    if(userContext && Object.hasOwn(userContext , 'org_name') &&  props.useOrgName){
      setMessages (["Analysing query","Retrieving sources from "+userContext['org_name'],"Looking for an answer within these sources","Fact-checking to ensure there are no AI hallucinations"] ) ;
    }
  }, [userContext])

  useEffect(() => {
    let timeoutId ; 
    if(index < messages.length ){
      timeoutId = setTimeout(() => {
        setDisplayMessage(messages[index]);
        setIndex(index => index+1);
        setTimer(timer => 4*timer);
      }, timer);}
  
    return () => {
      if(timeoutId){
        return () => clearTimeout(timeoutId);}
    }
  }, [index]);


  // needed because useEffect trigger only set a timeout. 
  useEffect(() => {
    setIndex(0);
    setTimer(1500);
    setDisplayMessage(messages[0]);
  }, [searchContext])
  
  

  return (
    <LoadingContainer key={'message_'+displayMessage} style={{height:'263px'}}  >
      {/* {!isSmallScreen ? */}
                    <div id='typewriter' className='bounce' style={{height:'100%',display:'flex', justifyContent:'center', alignItems:'center', paddingTop:'0px',paddingBottom:'0px'}} >
                    <p style={{margin: ' 0 0 43px 0'}}>{displayMessage} </p> </div>
                    {/* : <div id='typewriter' className='px-3 pt-0 pb-2 my-1'>We are finding the best answer for you. In the meantime, you may find the information you need below... </div>
                  } */}
    </LoadingContainer>
  );
};

export default LoadingIndicatorWithMessages;
